html,
body {
  font-family: 'Open Sans', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content-bg {
  background-color: #f7f7f7;
}

.content {
  padding: 30px;
}

.App-header {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

element.style {
  max-lines: 1;
  /* -webkit-line-clamp: 1; */
}
.card-title {
  margin-bottom: 0.5rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 1rem !important;
}

.card-text {
  margin-bottom: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 45px;
  font-size: 0.9rem;
  font-weight: 300;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem !important;
}

.blog-footer {
  padding: 0.8rem 0 0.3rem 0;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: 0.01em solid #e5e5e5;
}

.image-container {
  background-color: lightgray;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  color: white;
}

.navbar-dark .navbar-brand {
  color: #fff;
  font-size: 24px;
}

.loading-area {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: gray;
  padding: 30px;
}

.card a {
  color: black !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.embedContent {
  padding: 15px;
  border: 1px solid #dfdfdf;
  background-color: white;
  border-radius: 5px;
}

.embedContent table {
  /* background-color: red; */

  border: 1px solid #dee2e6;

  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.embedContent tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.embedContent td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.instructions-list li::marker {
  color: #008a35;
  font-size: 1.5em;
  font-weight: 700;
}

.alert-primary {
  color: #005d27;
  background-color: #008a3514;
  border-color: #008a3563;
}

.recipe-table td {
  border-top: 1px solid #f5f5f5 !important;
}
.recipe-table tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
